<template>
  <div class="panel-header panel-header-lg balck-bg">
    <img
      style="width: 250px"
      src="https://republica45.pt/wp-content/uploads/2023/01/Republica45_Logo.svg"
    />
  </div>
</template>

<script>
  import headerChart from "./HeaderChart";

  export default {
    name: "overview-header",
    data() {
      return {
        headerChartID: "headerChart",
      };
    },
    mounted() {
      // headerChart.createChart(this.headerChartID);
    },
  };
</script>

<style scoped>
  .balck-bg {
    background: #000 !important;
  }
  .panel-header img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
