<template>
  <div class="col-md-6 ml-auto mr-auto">
    <div class="card card-stats card-raised">
      <div class="card-body">
        <div class="row">
          <vnud-card class="card card-plain">
            <template v-slot:header>
              <div>
                <div
                  class="logo-container"
                  style="text-align: center; padding-bottom: 50px"
                >
                  <img
                    style="width: 250px"
                    src="https://republica45.pt/wp-content/uploads/2023/01/Republica45_Logo.svg"
                  />
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-6">
                <fg-input
                  v-model="user.email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                />
              </div>
              <div class="col-6">
                <fg-input
                  v-model="user.password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                />
              </div>
            </div>

            <template v-slot:footer>
              <div>
                <n-button
                  class="bg-black"
                  native-type="submit"
                  type="primary"
                  round
                  block
                  @click="login()"
                >
                  Entrar
                </n-button>
              </div>
            </template>
          </vnud-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { VeeInput } from "@/components";
  import { Form } from "vee-validate";
  import api from "@/api/index";
  export default {
    name: "vnud-login",
    components: {
      VeeInput,
      Form,
    },
    data() {
      return {
        user: {
          email: "",
          password: "",
        },
      };
    },
    methods: {
      async login() {
        let res = await api.login(this.user);
        if (res.length > 0) {
          localStorage.setItem("adminEventos", res[0].user_id);
          location.replace("/");
        } else {
          //MENSAGEM DE ERRO
        }
      },
    },
  };
</script>
<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
</style>
