<template>
  <div>
    <vnud-card>
      <template v-slot:header>
        <div>
          <h4 class="card-title">Eventos</h4>
        </div>
      </template>

      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="150" prop="titulo" label="Título">
            </el-table-column>
            <el-table-column min-width="200" prop="data" label="Data">
            </el-table-column>
            <el-table-column min-width="150" prop="registos" label="Inscritos">
            </el-table-column>
            <el-table-column min-width="150" header-align="right" label="">
              <template v-slot:default="props">
                <div class="text-right table-actions">
                  <el-tooltip content="Info" :open-delay="300" placement="top">
                    <n-button
                      @click="abrirLink(props.row.evento_id)"
                      class="bg-green"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i
                        class="now-ui-icons bg-green arrows-1_minimal-right"
                      ></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Info" :open-delay="300" placement="top">
                    <n-button
                      @click="
                        downloadCsv(props.row.lista_registos, props.row.titulo)
                      "
                      class="bg-black"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i
                        class="now-ui-icons bg-black arrows-1_cloud-download-93"
                      ></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Info" :open-delay="300" placement="top">
                    <n-button
                      @click="copiarEvento(props.row.evento_id)"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="now-ui-icons arrows-1_share-66"></i>
                    </n-button>
                  </el-tooltip>
                  <el-tooltip content="Info" :open-delay="300" placement="top">
                    <n-button
                      @click="
                        apagarEvento(props.row.evento_id, props.row.titulo)
                      "
                      class="bg-red"
                      type="info"
                      size="sm"
                      icon
                    >
                      <i class="now-ui-icons bg-red ui-1_simple-remove"></i>
                    </n-button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </vnud-card>
  </div>
</template>
<script>
  import {
    VnudCard,
    Table as NTable,
    AnimatedNumber,
    Progress as NProgress,
    AsyncWorldMap,
    Notification,
  } from "@/components";
  import api from "@/api/index";
  import { ElTable, ElTableColumn } from "element-plus";
  import { parse } from "json2csv";
  import Swal from "sweetalert2";
  import { useToast } from "vue-toastification";
  export default {
    components: {
      VnudCard,
      NTable,
      AnimatedNumber,
      NProgress,
      AsyncWorldMap,
    },

    components: {
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
    },
    data() {
      return {
        tableData: [],
        registos: [],
      };
    },
    methods: {
      apagarEvento(id, titulo) {
        var _this = this;
        const swalWithBootstrapButtons6 = Swal.mixin({
          customClass: {
            confirmButton: "btn bg-black",
            cancelButton: "btn bg-white",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons6
          .fire({
            title: "Apagar evento - " + titulo,
            text: "Esta ação é permanente!",
            confirmButtonText: "Apagar",
            cancelButtonText: "Cancelar",
            showCancelButton: true,
          })
          .then(async (result) => {
            if (result.value) {
              const swalWithBootstrapButtons6 = Swal.mixin({
                customClass: {
                  confirmButton: "btn bg-black",
                },
                buttonsStyling: false,
              });
              var json = {
                evento_id: id,
              };
              var res = await api.apagarEventos(json);
              if (res) {
                if (res[0] == "OK") {
                  _this.runToast("top-right", "success", "Evento apagado!");
                  this.inicio();
                  return;
                }
              }
            }
          });
      },
      downloadCsv(lista, titulo) {
        const jsonData = lista;

        const fields = ["nome"];
        const opts = { fields };
        const csv = parse(jsonData, opts);
        const blob = new Blob([csv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", titulo);
        link.click();
        this.runToast("top-right", "success", "Ficheiro transferido!");
      },
      contarRegistos(eventos, registos) {
        return eventos.map((event) => {
          return {
            ...event,
            registos: registos.filter((reg) => reg.event_id === event.evento_id)
              .length,
            lista_registos: registos.filter(
              (reg) => reg.event_id === event.evento_id
            ),
          };
        });
      },
      async inicio() {
        let eventos = await api.listarEventos();
        this.tableData = eventos;
        let registos = await api.listarRegistos();
        this.registos = registos;
        this.tableData = this.contarRegistos(this.tableData, registos);
        console.log(this.tableData);
      },
      copiarEvento(id) {
        var textArea = document.createElement("textarea");
        textArea.value =
          "https://eventos.republica45.pt/#/registo?evento_id=" +
          id +
          "&action=registar";
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        this.runToast("top-right", "success", "Link copiado para o clipboard!");
      },
      abrirLink(id) {
        this.$router.push("/registo?evento_id=" + id + "&action=registar");
      },
      runToast(pos, type, ownText, ownIcon) {
        const text = ownText;
        const icon = "now-ui-icons ui-1_bell-53";
        const content = {
          component: Notification,

          props: {
            ownText: ownText,
            ownIcon: ownIcon,
            icon: icon,
            text: text,
            type: type,
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: pos,
        });
      },
    },
    mounted() {
      this.inicio();
    },
  };
</script>
<style>
  .bg-black {
    background: #000 !important;
  }
  .bg-red {
    background: #ff0000 !important;
  }
  .bg-white {
    background: white !important;
    color: #000 !important;
  }
  .bg-green {
    background: #6ac07a !important;
    color: #fff !important;
  }
  .black {
    color: #000 !important;
  }
  .bold {
    font-weight: 700;
  }
  .card-pricing .card-icon.icon-black i {
    color: #18ce0f;
    box-shadow: 0px 9px 30px -6px #000;
  }
</style>
