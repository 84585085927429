<template>
  <div>
    <navbar :show-navbar="showMenu" id="nav">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{ toggled: showMenu }">
          <navbar-toggle-button @click="toggleNavbar" target="#nav">
          </navbar-toggle-button>
        </div>
      </div>

      <template v-slot:navbar-menu>
        <router-link
          to="/login"
          v-slot="{ href, isActive, isExactActive }"
          custom
        >
          <li
            class="nav-item"
            :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <a :href="href" class="nav-link">
              <i class="now-ui-icons users_circle-08"></i>
              Login
            </a>
          </li>
        </router-link>
      </template>
    </navbar>
    <div class="panel-header panel-header-lg bg-black">
      <img
        style="width: 250px"
        src="https://republica45.pt/wp-content/uploads/2023/01/Republica45_Logo.svg"
      />
    </div>

    <div class="content">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
  import { Navbar, NavbarToggleButton } from "@/components";

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
    },
    props: {
      backgroundColor: {
        type: String,
        default: "black",
      },
    },
    data() {
      return {
        showMenu: false,
        menuTransitionDuration: 250,
        pageTransitionDuration: 200,
        year: new Date().getFullYear(),
        pageClass: `${this.$route.name}-page`.toLowerCase(),
      };
    },
    methods: {
      toggleNavbar() {
        document.body.classList.toggle("nav-open");
        this.showMenu = !this.showMenu;
      },
      closeMenu() {
        document.body.classList.remove("nav-open");
        this.showMenu = false;
      },
      setPageClass(to) {
        this.pageClass = `${to.name}-page`.toLowerCase();
      },
    },
    beforeUnmount() {
      this.closeMenu();
    },
    beforeRouteUpdate(to, from, next) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next();
        }, this.menuTransitionDuration);
      } else {
        next();
      }
    },
    watch: {
      $route(to) {
        this.setPageClass(to);
      },
    },
  };
</script>
<style lang="css">
  .panel-header img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
