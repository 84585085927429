import axios from "axios";
 
const instance = axios.create({
  baseURL: "https://yourcode-staging.com/api-republica-eventos/public",
  headers: {
    Authorization: "Basic ZGVjYXRobG9uX2FwaV91c2VyOmU2Sy83NUozKXBZfnNed0w=",
    "content-type": "application/json",
  },
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});

export default {
  async login(data) {
    try {
      const res = await instance.post("/api/login", data);
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarUtilizadores() {
    try {
      const res = await instance.get("/api/listar/utilizadores");
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarEvento(data) {
    try {
      const res = await instance.post("/api/listar/evento",data);
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarEventos() {
    try {
      const res = await instance.get("/api/listar/eventos");
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarRegistos() {
    try {
      const res = await instance.get("/api/listar/registos");
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarRegistados(data) {
    try {
      const res = await instance.post("/api/listar/registos",data);
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async apagarEventos(data) {
    try {
      const res = await instance.post("/api/apagar/eventos",data);
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async registarCandidado(data) {
    try {
      const res = await instance.post("/api/registar",data);
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async adicionarEvento(data) {
    try {
      const res = await instance.post("/api/adicionar/evento",data);
      const news = Object.values(res.data);
      return news;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
}