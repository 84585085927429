<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised p-3">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <form method="#" action="#" @submit.prevent>
                  <div>
                    <fg-input
                      label="Titulo do evento"
                      type="text"
                      placeholder="Titulo do evento"
                      v-model="evento.titulo"
                    >
                    </fg-input>
                    <fg-input
                      v-model="evento.vagas"
                      label="Vagas"
                      type="text"
                      placeholder="Vagas"
                    >
                    </fg-input>
                    <n-button @click="submeter" type="primary" class="bg-black"
                      >Adicionar</n-button
                    >
                  </div>
                </form>
              </div>
              <div class="col-6">
                <form method="#" action="#" @submit.prevent>
                  <div>
                    <fg-input
                      v-model="evento.data"
                      label="Data do evento"
                      type="text"
                      placeholder="Data do evento"
                    >
                    </fg-input>
                    <label class="">Descrição</label>
                    <textarea
                      v-model="evento.descricao"
                      type="text"
                      placeholder="Descrição"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="criado">
      <div class="col-md-12">
        <div class="card card-stats card-raised p-3">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                Link de registo:<br />
                {{ link }}<br />
                <n-button
                  @click="copiarLink(link)"
                  type="primary"
                  class="bg-black"
                  >Copiar</n-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    VnudCard,
    Table as NTable,
    AnimatedNumber,
    Progress as NProgress,
    AsyncWorldMap,
    Notification,
  } from "@/components";
  import api from "@/api/index";
  import { useToast } from "vue-toastification";

  export default {
    components: {
      VnudCard,
      NTable,
      AnimatedNumber,
      NProgress,
      AsyncWorldMap,
    },
    data() {
      return {
        criado: false,
        evento: {
          id: "",
          titulo: "",
          data: "",
          vagas: "",
          descricao: "",
        },
      };
    },
    methods: {
      runToast(pos, type, ownText, ownIcon) {
        const text = ownText;
        const icon = "now-ui-icons ui-1_bell-53";
        const content = {
          component: Notification,

          props: {
            ownText: ownText,
            ownIcon: ownIcon,
            icon: icon,
            text: text,
            type: type,
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: pos,
        });
      },
      copiarLink(link) {
        var textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
        this.runToast("top-right", "success", "link copiado para o clipboard!");
      },
      generateRandomString() {
        let characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";
        for (let i = 0; i < 50; i++) {
          randomString += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
        return randomString;
      },
      async submeter() {
        var vazio = false;
        this.evento.id = this.generateRandomString();
        for (let key in this.evento) {
          if (this.evento[key] == "") {
            vazio = true;
          }
        }
        if (vazio) {
          this.runToast(
            "top-right",
            "warning",
            "Tens de preencher todos os campos!"
          );
          return;
        }
        let res = await api.adicionarEvento(this.evento);
        if (res) {
          if (res[0] == "OK") {
            this.runToast("top-right", "success", "Evento criado");
            this.link =
              "https://eventos.republica45.pt/#/registo?evento_id=" +
              this.evento.id +
              "&action=registar";
            this.criado = true;
            this.evento = {
              id: "",
              titulo: "",
              data: "",
              vagas: "",
              descricao: "",
            };
            return;
          }
        } else {
          this.runToast("top-right", "warning", "Aconteceu um erro!");
        }
      },
    },
    // mounted() {
    //   this.inicio();
    // },
  };
</script>
<style>
  .bg-black {
    background: #000 !important;
  }
  .black {
    color: #000 !important;
  }
  .bold {
    font-weight: 700;
  }
  .card-pricing .card-icon.icon-black i {
    color: #18ce0f;
    box-shadow: 0px 9px 30px -6px #000;
  }
</style>
