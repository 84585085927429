<template>
  <router-view></router-view>
</template>

<style>
  .sidebar[data-color="black"] .nav li > a.active:not([data-toggle="collapse"]),
  .sidebar[data-color="black"]
    .nav
    li
    > a.active:not([data-toggle="collapse"])
    i {
    color: black !important;
  }
  .sidebar .logo::after {
    display: none !important;
  }
  .sidebar .logo a.logo-mini {
    padding-top: 5%;
    width: 50% !important;
  }
  .navbar-minimize {
    padding-top: 2%;
  }
  .bg-black {
    background: #000 !important;
  }
  .content {
    margin-top: -30px;
  }
</style>
