<template>
  <div>
    <vnud-card>
      <h4 class="card-title pl-3">
        XS Grid
        <small>Always Horizontal</small>
      </h4>
      <div class="row">
        <div class="col-4">
          <vnud-card>
            <div class="text-center">
              <code>col-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-4">
          <vnud-card>
            <div class="text-center">
              <code>col-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-4">
          <vnud-card>
            <div class="text-center">
              <code>col-4</code>
            </div>
          </vnud-card>
        </div>
      </div>

      <h4 class="card-title pl-3">
        SM Grid
        <small>Collapsed at 768px</small>
      </h4>
      <div class="row">
        <div class="col-sm-4">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-sm-4">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-sm-4">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-4</code>
            </div>
          </vnud-card>
        </div>
      </div>

      <h4 class="card-title pl-3">
        MD Grid
        <small>Collapsed at 992px</small>
      </h4>
      <div class="row">
        <div class="col-md-4">
          <vnud-card>
            <div class="text-center">
              <code>col-md-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-md-4">
          <vnud-card>
            <div class="text-center">
              <code>col-md-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-md-4">
          <vnud-card>
            <div class="text-center">
              <code>col-md-4</code>
            </div>
          </vnud-card>
        </div>
      </div>

      <h4 class="card-title pl-3">
        LG Grid
        <small>Collapsed at 1200px</small>
      </h4>
      <div class="row">
        <div class="col-lg-4">
          <vnud-card>
            <div class="text-center">
              <code>col-lg-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-lg-4">
          <vnud-card>
            <div class="text-center">
              <code>col-lg-4</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-lg-4">
          <vnud-card>
            <div class="text-center">
              <code>col-lg-4</code>
            </div>
          </vnud-card>
        </div>
      </div>

      <h4 class="card-title pl-3">
        Mixed Grid
        <small>Showing different sizes on different screens</small>
      </h4>
      <div class="row">
        <div class="col-sm-6 col-lg-3">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-6 col-lg-3</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-sm-6 col-lg-3">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-6 col-lg-3</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-sm-6 col-lg-3">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-6 col-lg-3</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-sm-6 col-lg-3">
          <vnud-card>
            <div class="text-center">
              <code>col-sm-6 col-lg-3</code>
            </div>
          </vnud-card>
        </div>
      </div>

      <h4 class="card-title pl-3">
        Offset Grid
        <small>Adding some space when needed</small>
      </h4>
      <div class="row">
        <div class="col-md-3">
          <vnud-card>
            <div class="text-center">
              <code>col-md-3</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-md-3 offset-md-6">
          <vnud-card>
            <div class="text-center">
              <code>col-md-3 offset-md-6</code>
            </div>
          </vnud-card>
        </div>

        <div class="col-md-4 offset-md-1">
          <vnud-card>
            <div class="text-center">
              <code>col-md-4 offset-md-1</code>
            </div>
          </vnud-card>
        </div>
        <div class="col-md-4 offset-md-2">
          <vnud-card>
            <div class="text-center">
              <code>col-md-4 offset-md-2</code>
            </div>
          </vnud-card>
        </div>

        <div class="col-md-6 offset-md-3">
          <vnud-card>
            <div class="text-center">
              <code>col-md-6 offset-md-3</code>
            </div>
          </vnud-card>
        </div>
      </div>
    </vnud-card>
    <h3 class="title mt-4 text-center">Paragraphs</h3>
    <vnud-card>
      <div>
        <div class="row">
          <div class="col-sm-6">
            <h3>Some card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment. His many legs, pitifully thin
              compared with the size of the rest of him, waved about helplessly
              as he looked. "What's happened to me?" he thought.
            </p>
          </div>

          <div class="col-sm-6">
            <h3>Another card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment. His many legs, pitifully thin
              compared with the size of the rest of him, waved about helplessly
              as he looked. "What's happened to me?" he thought.
            </p>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-sm-4">
            <h3>Some card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment.
            </p>
          </div>

          <div class="col-sm-4">
            <h3>Another card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment.
            </p>
          </div>

          <div class="col-sm-4">
            <h3>Another card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment.
            </p>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-sm-4">
            <h3>Some card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment.
            </p>
          </div>

          <div class="col-sm-8">
            <h3>Another card-title Here</h3>
            <p>
              One morning, when Gregor Samsa woke from troubled dreams, he found
              himself transformed in his bed into a horrible vermin. He lay on
              his armour-like back, and if he lifted his head a little he could
              see his brown belly, slightly domed and divided by arches into
              stiff sections. The bedding was hardly able to cover it and seemed
              ready to slide off any moment. One morning, when Gregor Samsa woke
              from troubled dreams, he found himself transformed in his bed into
              a horrible vermin. He lay on his armour-like back, and if he
              lifted his head a little he could see his brown belly, slightly
              domed and divided by arches into stiff sections. The bedding was
              hardly able to cover it and seemed ready to slide off any moment.
            </p>
          </div>
        </div>
      </div>
    </vnud-card>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
