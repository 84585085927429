<template>
  <navbar :show-navbar="showNavbar" id="navigation">
    <div class="navbar-wrapper">
      <div class="navbar-toggle" :class="{ toggled: $sidebar.showSidebar }">
        <navbar-toggle-button @click="toggleSidebar"> </navbar-toggle-button>
      </div>
      <a class="navbar-brand" href="javascript:;">
        {{ $route.name }}
      </a>
    </div>
    <button
      @click="toggleNavbar"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navigation"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>

    <template v-slot:navbar-menu>
      <li class="nav-item">
        <a @click="logout()" href="#" class="nav-link">
          <i class="now-ui-icons sport_user-run"></i>
          Logout
        </a>
      </li>
    </template>
  </navbar>
</template>
<script>
  import { Navbar, NavbarToggleButton } from "@/components";

  export default {
    components: {
      Navbar,
      NavbarToggleButton,
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
    },
    data() {
      return {
        activeNotifications: false,
        showNavbar: false,
      };
    },
    methods: {
      logout() {
        localStorage.removeItem("adminEventos");
        console.log(this.$route.path);
        if (this.$route.path == "/registo") {
          location.reload();
        } else {
          this.$router.replace({ path: "/" });
        }
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      toggleNavbar() {
        this.showNavbar = !this.showNavbar;
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
    },
  };
</script>
<style></style>
