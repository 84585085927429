<template>
  <div>
    <div v-if="!eventoFalso && !jaRegistado && !jaRegistou" class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="statistics">
                  <div class="info">
                    <div class="icon black">
                      <i class="now-ui-icons ui-1_calendar-60"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number
                        :value="Number(evento[0].vagas)"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Vagas</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="statistics">
                  <div class="info">
                    <div class="icon black">
                      <i class="now-ui-icons users_single-02"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="registados"></animated-number>
                    </h3>
                    <h6 class="stats-title">Inscritos</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!eventoFalso && !jaRegistado && !jaRegistou" class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="col-lg-6 col-md-12 text-center">
          <vnud-card
            class="card-contributions"
            :title="evento[0].titulo"
            :sub-title="evento[0].data"
            :description="evento[0].descricao"
          >
            <template v-slot:footer>
              <div class="row">
                <div class="col-8">
                  <div class="card-stats justify-content-center">
                    <div class="col-md-9">
                      <fg-input
                        v-model="nome"
                        placeholder="Primeiro e último nome"
                      >
                      </fg-input>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="card-stats justify-content-center">
                    <n-button
                      v-if="!cheio"
                      class="bg-black"
                      type="primary"
                      @click="submeter()"
                      >Submeter</n-button
                    >
                    <p v-else class="info-title bold">Inscrições fechadas</p>
                  </div>
                </div>
              </div>
            </template>
          </vnud-card>
        </div>
      </div>
    </div>
    <div v-if="!eventoFalso && jaRegistado" class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="col-lg-6 col-md-12 text-center">
          <vnud-card
            class="card-pricing"
            no-footer-line
            :category="evento[0].titulo"
          >
            <div class="card-icon icon-black">
              <i class="now-ui-icons ui-1_check"></i>
            </div>
            <ul>
              <li>Obrigado pelo teu registo!</li>
            </ul>
          </vnud-card>
        </div>
      </div>
    </div>
    <div v-if="jaRegistou && !jaRegistado" class="row">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="col-lg-6 col-md-12 text-center">
          <vnud-card
            class="card-pricing"
            no-footer-line
            :category="evento[0].titulo"
          >
            <div class="card-icon icon-black">
              <i class="now-ui-icons ui-1_check"></i>
            </div>
            <ul>
              <li>
                Já te encontras registado neste evento. Obrigado pela tua
                presença!
              </li>
            </ul>
          </vnud-card>
        </div>
      </div>
    </div>
    <div v-if="eventoFalso" class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body padding">
            <div class="row">
              <div class="col-md-12">
                <div class="statistics">
                  <div class="info">
                    <div class="icon black"></div>
                    <h3 class="stats-title black" style="text-align: center">
                      <span style="color: #0204e3"
                        >Bem-vindo à nossa plataforma de eventos! 😍</span
                      ><br />
                      <span style="color: #f1716c"
                        >Aqui as coisas acontecem rápido, logo precisas ser
                        rápido também! </span
                      ><span style="color: #fcaf17"
                        >Lembra-te, os primeiros a se inscreverem são os
                        primeiros a desfrutar dos eventos incríveis que temos
                        planeado.</span
                      ><br />
                      <span style="color: #ef4e23">
                        Não percas tempo e garante a tua vaga agora, antes que
                        alguém o faça por ti!</span
                      ><br /><span style="color: #262261"
                        >Só tens de esperar pelo link de registo e claro,</span
                      >
                      <span style="color: #6ac07a">
                        ativar as notificações do grupo no whatsapp!</span
                      >
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    VnudCard,
    Table as NTable,
    AnimatedNumber,
    Progress as NProgress,
    AsyncWorldMap,
    Notification,
  } from "@/components";
  import api from "@/api/index";
  import { useToast } from "vue-toastification";

  export default {
    components: {
      VnudCard,
      NTable,
      AnimatedNumber,
      NProgress,
      AsyncWorldMap,
    },
    data() {
      return {
        evento: [],
        nome: "",
        eventoFalso: true,
        rendered: false,
        jaRegistou: false,
        cheio: false,
        registados: 0,
        jaRegistado: false,
      };
    },
    methods: {
      runToast(pos, type, ownText, ownIcon) {
        const text = ownText;
        const icon = "now-ui-icons ui-1_bell-53";
        const content = {
          component: Notification,

          props: {
            ownText: ownText,
            ownIcon: ownIcon,
            icon: icon,
            text: text,
            type: type,
          },
        };
        const toast = useToast();
        toast(content, {
          hideProgressBar: true,
          icon: false,
          closeButton: false,
          position: pos,
        });
      },
      async inicio() {
        let link = window.location.href;
        let comeco = link.indexOf("=") + 1;
        let fim = link.indexOf("&");
        let url = link.substring(comeco, fim);

        if (!url || url == "") {
          this.eventoFalso = true;
          return;
        }
        var jsonEvento = {
          evento_id: url,
        };
        let evento = await api.listarEvento(jsonEvento);
        if (evento.length <= 0) {
          this.eventoFalso = true;
          return;
        }
        this.evento = evento;
        var jaRegistou = localStorage.getItem("registoEvento");
        if (jaRegistou) {
          if (
            JSON.parse(jaRegistou).registo &&
            JSON.parse(jaRegistou).evento_id == url
          ) {
            this.eventoFalso = false;
            this.jaRegistou = true;
            return;
          }
        }

        var registados = await api.listarRegistados(jsonEvento);
        this.registados = registados.length;
        if (this.registados == this.evento[0].vagas) {
          this.cheio = true;
        }

        this.eventoFalso = false;
      },
      async submeter() {
        if (this.nome == "") {
          this.runToast("top-right", "warning", "Preenche o nome!");
          return;
        }
        let link = window.location.href;
        let comeco = link.indexOf("=") + 1;
        let fim = link.indexOf("&");
        let url = link.substring(comeco, fim);
        var jsonEvento = {
          evento_id: url,
        };
        var registados = await api.listarRegistados(jsonEvento);
        console.log(registados, this.evento[0].vagas);
        if (registados.length >= this.evento[0].vagas) {
          this.runToast(
            "top-right",
            "warning",
            "Já não existe vagas para este evento!"
          );
          return;
        }
        var jsonRegisto = {
          nome: this.nome,
          evento_id: url,
        };
        let res = await api.registarCandidado(jsonRegisto);
        if (res) {
          if (res[0] == "OK") {
            this.runToast(
              "top-right",
              "success",
              "Parabéns, estás entre os " + this.evento[0].vagas + " primeiros."
            );
            localStorage.setItem(
              "registoEvento",
              JSON.stringify({ registo: true, evento_id: url })
            );
            this.inicio();
            this.jaRegistado = true;
            return;
          }
        }
      },
    },
    mounted() {
      this.inicio();
    },
  };
</script>
<style>
  .bg-black {
    background: #000 !important;
  }
  .black {
    color: #000 !important;
  }
  .bold {
    font-weight: 700;
  }
  .card-pricing .card-icon.icon-black i {
    color: #18ce0f;
    box-shadow: 0px 9px 30px -6px #000;
  }
  .padding {
    padding-top: 15% !important;
    padding-bottom: 15% !important;
  }
</style>
